<template>
  <div v-if="blog" id="BlogItem">
    <Hero :src="getPostImage(blog)">
      <h1>
        {{ blog.title }}
      </h1>
      <HeroScrollDown hash="#blog-content" slot="outside" />
    </Hero>
    <section id="blog-content" :class="{ 'has-next': blogNext }">
      <Wrap class="md">
        <p>
          <strong>{{ $d(new Date(blog.created_at), 'short') }}</strong>
        </p>
        <Markdown :hasStyles="true" :markdown="blog.content" />
        <BtnShare />
      </Wrap>
    </section>
    <div v-if="blogNext" id="blog-next">
      <Wrap>
        <h3>{{ $t('next') }}</h3>
        <CardBlog :blog="blogNext" />
      </Wrap>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import BtnShare from 'views/components/BtnShare'
import CardBlog from 'views/components/CardBlog'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [
    Meta(
      ({ blog }) => blog?.title,
      ({ blog }) => blog?.content,
      ({ blog }) => blog?.image.url,
    ),
  ],
  components: { BtnShare, CardBlog, Hero, HeroScrollDown, Markdown },
  computed: {
    blog() {
      return this.query('blog', { slug: this.$route.params.slug }, true)
    },
    blogNext() {
      return this.query('blog', {
        locale: this.locale,
        $sort: {
          id: -1,
        },
        id: {
          $lt: this.blog.id,
        },
      }, true)
    },
    ...getters('getPostImage'),
  },
  methods: actions('FETCH_BLOG'),
  async beforeMount() {
    await this.LOAD(this.FETCH_BLOG())
  },
}
</script>

<style lang="scss">
#BlogItem {
  .Hero {
    min-height: 70vh;

    h1 {
      font-size: $h2;
    }

    img {
      filter: opacity(0.3);
      height: 100%;
      width: 100%;
    }
  }
}

#blog-content {
  background: $white;

  p {
    margin-bottom: 2rem;
    // text-indent: 1rem;
  }

  .BtnShare {
    float: right;

    > .BtnRound {
      background: $grey-lighter;
    }
  }

  &.has-next {
    margin-bottom: 10rem;
  }
}

#blog-next {
  margin-bottom: 8rem;

  h3 {
    margin-bottom: 6rem;
    text-align: center;
  }
}
</style>

<i18n>
{
  "de": {
    "next": "Weiterlesen"
  },
  "en": {
    "next": "Read next"
  },
  "it": {
    "next": ""
  },
  "lt": {
    "next": "Kitas įrašas"
  },
  "pl": {
    "next": "Czytaj następny"
  }
}
</i18n>
