<template>
  <div id="Blog">
    <Hero>
      <h1>
        {{ $t('title') }}
      </h1>
    </Hero>

    <section id="SectionBlogs">
      <Wrap>
        <CardBlog
          v-for="blog in blogs"
          :key="blog.id"
          :blog="blog" />

        <PaginationShowMore
          v-if="blogsCount > limitBlogs"
          :limit.sync="limitBlogs"
          name="blog"
          :total="blogsCount" />
      </Wrap>
    </section>
  </div>
</template>

<script>
import { actions, getters } from '../../utils'
import CardBlog from 'views/components/CardBlog'
import Hero from 'views/components/Hero'
import Meta from 'views/mixins/Meta'
import PaginationShowMore from 'views/components/PaginationShowMore'

export default {
  mixins: [
    Meta(require('@/assets/social/news.jpg')),
  ],
  components: { CardBlog, Hero, PaginationShowMore },
  data() {
    return {
      customQuery: {
        $or: [
          { locale: this.locale },
          { showOnAllLocales: true },
        ],
      },
      limitBlogs: 10,
    }
  },
  computed: {
    ...getters(),
    blogs() {
      const queryParams = {
        $or: [
          { locale: this.locale },
          { showOnAllLocales: true },
        ],
        $sort: {
          // sorting by boolean do not work
          // featured: -1,
          created_at: -1,
        },
        $limit: this.limitBlogs,
      }

      return this.query('blog', queryParams)
    },
    blogsCount() {
      return this.query('blog', {
        $or: [
          { locale: this.locale },
          { showOnAllLocales: true },
        ],
      }).length
    },
  },
  methods: actions('FETCH_BLOG'),
  created() {
    this.LOAD(Promise.all([
      this.FETCH_BLOG(),
    ]))
  },
}
</script>

<style lang="scss">
#Blog {
  .Hero {
    background-image: url('../../../assets/ellipses.png');
    background-position: right;
    background-size: cover;
    text-align: center;

    h1 {
      margin-bottom: 4rem;
    }

    .filters {
      > .Btn {
        margin-right: 1rem;

        &.active {
          background: $black-bis;
          border-color: $black-bis;
        }
      }
    }
  }

  #media {
    padding-top: 0;
  }
}
</style>

<i18n>
{
  "de": {
    "title": "Blog"
  },
  "en": {
    "title": "Blog"
  },
  "it": {
    "title": ""
  },
  "lt": {
    "title": "Tinklaraštis"
  },
  "pl": {
    "title": "Blog"
  }
}
</i18n>
