const translations = {
  pl: {
    faqSections: [
      {
        title: 'Najczęściej zadawane pytania',
        faq: [
          {
            q: 'Czym zajmuję się firma Sun Investment Group/SIG?',
            a: 'Sun Investment Development zajmuje się wydzierżawianiem gruntów pod budowę farm fotowoltaicznych. Realizujemy Politykę Energetyczną poprzez konstrukcję i rozwój instalacji. Projekty prowadzimy od początku do końca, czyli od momentu podpisania umowy dzierżawy, aż do momentu finalizacji inwestycji. Zrealizowaliśmy już 109 projektów farm fotowoltaicznych w Polsce, aktualnie pracujemy nad kolejnymi, o łącznej mocy ponad 1.4 GW.',
          },
        ],
      },
      {
        title: 'Grunt pod dzierżawę',
        faq: [
          {
            q: 'Jakie wymagania musi spełniać działka, żeby można ją było wydzierżawić pod instalację farmy fotowoltaicznej?',
            a: 'Najważniejsze, aby działka spełniała następujące warunki:\n\na) powierzchnia terenu – min. 10 ha,\n\nb) działki bez planu zagospodarowania przestrzennego lub z planem pod fotowoltaikę/produkcję,\n\nc) niskie klasy gruntów (IV, V, VI) oraz nieużytki.\n\nKoniecznie jest także, aby działka posiadała księgę wieczystą. W przypadku gdy jej nie ma pomagamy w jej założeniu. Pozostałe aspekty sprawdzane są przez odpowiedni dział w naszej firmie.',
          },
          {
            q: 'Czy jeśli mój grunt ma mniej niż 1,2 ha mogę oddać go w dzierżawę dla SIG?',
            a: 'Jest taka możliwość, jeżeli na działce oraz na nieruchomości znajdującej się w bezpośrednim sąsiedztwie, nie znajdują się żadne przeszkody planistyczne (ciek wodny, rów, linia energetyczna, drzewa, zabudowania), wtedy możemy zrealizować projekt na powierzchni ok. 1,2 ha.  W przeciwnym razie nic straconego - zawsze można przekonać sąsiada i wejść we współpracę z nami, razem z nim.',
          },
          {
            q: 'Czy klasa gruntu ma znaczenia?',
            a: 'Tak. Interesują nas jedynie grunty słabej klasy (IV, V, VI) oraz nieużytki.',
          },
          {
            q: 'Moje pola leżą na wzgórzu, czy to dobry grunt?',
            a: 'Tego typu kwestie rozpatrujemy indywidualnie. Prosimy o kontakt z naszą firmą pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są nasze możliwości.',
          },
          {
            q: 'Na mojej działce znajduje się las, czy taki obszar się nadaje?',
            a: 'Drzewa oraz budynki mogą negatywnie wpływać na efektywność instalacji fotowoltaicznej, poprzez jej zacienianie, dlatego instalacja musi być od nich odsunięta około 30-40 metrów. Prosimy o kontakt z naszą firmą pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są możliwości.',
          },
          {
            q: 'Moja nieruchomość znajduje się w obszarze Natura2000, czy jest to problem?',
            a: 'Wydzierżawiamy działki znajdujące się w obszarze Natura2000, jeżeli ich powierzchnia to minimum 6 hektarów.',
          },
          {
            q: 'Działka znajduje się w Obszarze Chronionego Krajobrazu, może być?',
            a: 'Zazwyczaj tak, prosimy o kontakt z naszymi specjalistami pod adresem: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub numerem telefonu: [884 202 788](tel:884202788), a zweryfikujemy jakie są nasze możliwości.',
          },
        ],
      },
      {
        title: 'Dzierżawa',
        faq: [
          {
            q: 'Jak mogę się do Was zgłosić?',
            a: 'Zgłoszenia można dokonać telefonicznie: [884 202 788](tel:884202788) lub mailowo: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl) lub [poprzez zakładkę](/pl/faq/landowners#FooterContact).',
          },
          {
            q: 'Jaki jest okres dzierżawy?',
            a: 'Czas dzierżawy to 29 lat i jest to punkt umowy, który nie podlega negocjacjom. Ten okres związany jest m.in. z gwarancją modułów fotowoltaicznych, która wynosi 25 lat.',
          },
          {
            q: 'Jak wygląda umowa, gdzie mogę ja zobaczyć?',
            a: 'Umowa jest przygotowywana indywidualnie, prosimy o kontakt telefoniczny: [884 202 788](tel:884202788) lub mailowy: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl), a wszystko zostanie ustalone.',
          },
          {
            q: 'Ile dostanę za dzierżawę?',
            a: 'Każdy przypadek rozpatrywany jest indywidualnie. Bierzemy pod uwagę m.in. występowanie przeszkód planistycznych, dostęp do drogi czy odległość od linii energetycznych. Zapraszamy do kontaktu: [884 202 788](tel:884202788) lub mailowo: [gliwice@sigziemia.pl](mailto:gliwice@sigziemia.pl), a dokonamy szczegółowej wyceny.',
          },
          {
            q: 'Kiedy będą otrzymywał pieniądze?',
            a: 'Oferujemy dwa różne czynsze dzierżawne, wypłacane zawsze za rok z góry lub z dołu. Pierwszy z nich płacony jest od momentu podpisania umowy do czasu rozpoczęcia budowy. Drugi, wyższy czynsz właściciel nieruchomości otrzymuje co roku podczas eksploatacji instalacji aż do czasu jej rozbiórki.',
          },
          {
            q: 'Czy roczna kwota czynszu dzierżawnego jest waloryzowana?',
            a: 'Tak, kwota czynszu jest waloryzowana według wskaźnika GUS.',
          },
          {
            q: 'Co dzieje się po zakończeniu umowy, co z panelami?',
            a: 'Sun Investment Group kompleksowo zajmuje się wszystkimi projektami. Po zakończeniu umowy demontujemy farmę i przywracamy ziemię do pierwotnego stanu. Ponosimy wszystkie związane z tym koszty, jak również koszty recyklingu całej instalacji.',
          },
        ],
      },
      {
        title: 'Fotowoltaika – bezpieczny zysk',
        faq: [
          {
            q: 'Co zyskam podpisując z Wami umowę?',
            a: 'Współpraca z nami to możliwość posiadania dodatkowego i stabilnego źródła dochodu. Po podpisaniu umowy. Dzierżawca ma stały kontakt z kierownikiem projektu, który nadzoruje proces administracyjny (decyzje o uwarunkowaniach środowiskowych, warunki zabudowy itp.). Dodatkowo, wspólnie przyczyniamy się do rozwoju „zielonej” energii w naszym kraju, dzięki której w przyszłości będziemy mogli oddychać czystszym powietrzem.',
          },
          {
            q: 'Co ludzie zyskują dzięki fotowoltaice?',
            // eslint-disable-next-line xss/no-mixed-html
            a: 'Fotowoltaika to ekologiczne i bezpieczne rozwiązanie, które pozwala produkować prąd. Dzięki zastosowaniu fotowoltaiki zmniejszamy ilość produkowanego CO<sub>2</sub> oraz innych zanieczyszczeń a co za tym idzie smogu, który staje się w Polsce coraz poważniejszym problemem. Zanieczyszczenia powietrza, z którymi zmagamy się na co dzień wpływają źle nie tylko na nasze samopoczucie, ale i zdrowie, w sposób szczególny narażając dzieci i osoby starsze.',
          },
          {
            q: 'Jakie są zagrożenia z fotowoltaiki?',
            a: 'Fotowoltaika to bezpieczna metoda produkcji energii elektrycznej, która nie stwarza zagrożeń dla otoczenia. Jest bezpieczna zarówno dla ludzi jak i środowiska naturalnego. Budowa farm fotowoltaicznych wymaga spełnienia odpowiednich, bardzo surowych norm, które są zawsze są pieczołowicie wypełniane przez naszą firmę. Parametry, które uzyskujemy nie tylko są zgodne z obowiązującymi normami, ale również znajdują się znacznie poniżej górnych limitów.\n\nFarma fotowoltaiczna jest praktycznie bezobsługowa. Nie ma tutaj mowy również o ściekach. Moduły zamontowane są pod kątem i obmywane są przez deszcz. W przypadku jego braku, wyspecjalizowana firma umyje je wodą - bez użycia detergentów, nie powodujemy więc żadnych zanieczyszczeń gruntu. Elektrownia słoneczna nie jest także źródłem istotnego promieniowania elektromagnetycznego, większe wartości promieniowania emitują telefony komórkowe, które mamy cały czas przy sobie. Jeśli chodzi o hałas, to jedynym jego źródłem może być transformator, ukryty w stacji transformatorowej, jest on jednak zawsze od terenów chronionych akustycznie. Farma fotowoltaiczna nie pracuje w nocy, a wszelkie badania przeprowadzone na istniejących instalacjach wykazały, że tego typu instalacja nie przekracza żadnych dopuszczalnych norm hałasu.',
          },
          {
            q: 'A co ze zwierzętami?',
            a: 'Nasze ogrodzenie podniesione jest od gruntu, dzięki temu migracja drobnych zwierząt nie jest zakłócona. Moduły posiadają również powłokę antyrefleksyjną, zatem ptaki nie zostaną oślepione, ani nie pomylą instalacji z taflą wody. W żaden sposób nie zagrażamy więc zwierzętom, zarówno dzikim jak i gospodarskim.',
          },
          {
            q: 'A co z roślinami?',
            a: 'Powierzchnia pomiędzy stołami, na których zamontowane są moduły jest wciąż powierzchnią biologicznie czynną. Po zakończeniu budowy obszar ten zasiewamy trawami lub zostawiamy do porośnięcia przez lokalną roślinność, w zależności od otrzymanej decyzji o uwarunkowaniach środowiskowych.\n\nSame moduły montowane są na wysokości min. 1,2 m, dlatego wystarczy, gdy teren inwestycji wykosimy raz czy dwa razy w roku - zadaniem tym zajmuje się kompleksowo nasza firma. Co ważne, nie używamy, żadnych nawozów, odżywek, herbicydów (typu Roundup), itp. nie powodujemy więc żadnego skażenia czy zanieczyszczenia gleby.',
          },
          {
            q: 'Czy farma jest ubezpieczona?',
            a: 'Oczywiście, farma fotowoltaiczna jest dużą inwestycją i musi zostać ubezpieczona. Sun Investment Group zajmuje się inwestycją kompleksowo inwestycją, pokrywamy więc również koszty ubezpieczenia i ponosimy koszty wszelkich napraw itp.',
          },
        ],
      },
      {
        title: 'Nasze farmy w Polsce',
        faq: [
          {
            q: 'Chce zobaczyć farmę na żywo, gdzie mogę ją zobaczyć?',
            a: 'Zdjęcia projektów zrealizowanych już w Polsce przez naszą firmę, wraz z ich lokalizacjami znajdują się na naszej stronie: [https://suninvestmentgroup.com/portfolio](https://suninvestmentgroup.com/portfolio). Dzięki podanym lokalizacjom łatwo odnaleźć je w konkretnych miejscowościach, gdzie można zobaczyć je na żywo.',
          },
        ],
      },
    ],
  },
  en: {
    faqSections: [
      {
        title: 'Najczęściej zadawane pytania',
        faq: [
          {
            q: 'What Sun Investment Group / SIG does?',
            a: `Sun Investment Development deals in leasing land for solar farms.

We implement the Energy Policy through the construction and development of installations. We run projects from beginning to end, i.e. from the moment of signing the lease agreement until the implementation of the investment. We have 109 existing installations, and over 40 are currently under preparation. We have already leased over 1000 hectares throughout the country!`,
          },
        ],
      },
    ],
  },
}

export default Object
  .fromEntries(Object
    .entries(translations)
    .map(([locale, { faqSections }]) => {
      let counter = 1

      return [
        locale,
        {
          faqSections: faqSections.map(section => ({
            ...section,
            faq: section.faq.map(faq => ({
              ...faq,
              number: counter++,
            })),
          })),
        },
      ]
    }))
