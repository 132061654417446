<template>
  <Card class="CardBlog">
    <Splitter :left="5" :right="7">
      <Pic
        :alt="blog.title"
        class="image"
        slot="left"
        :src="getPostImagePreview(blog)" />

      <div slot="right" class="body">
        <div class="meta">
          <div class="date">
            {{ $d(new Date(blog.created_at), 'short') }}
          </div>
        </div>
        <Route class="title" :params="{ slug: blog.slug }" to="BlogItem">
          {{ blog.title }}
        </Route>
        <div class="preview">
          {{ blog.previewText }}
        </div>
        <BtnShare :to="{ name: 'Blog', params: { slug: blog.slug } }" />
      </div>
    </Splitter>
  </Card>
</template>

<script>
import BtnShare from './BtnShare'
import Splitter from './Splitter'
import { getters } from '../utils'

export default {
  components: { BtnShare, Splitter },
  props: {
    blog: Object,
  },
  computed: {
    ...getters('getPostImagePreview'),
  },
}
</script>

<style lang="scss">
.CardBlog {
  border: 0;

  .show-more {
    margin-top: 2rem;

    a {
      color: $white;
    }
  }

  .Splitter-body {
    height: 100%;
    max-width: unset;
    padding: 2rem;
    width: 100%;
    @include lg {
      padding: 5.75rem 4rem 4rem;
    }
  }

  .image > img {
    display: block;
    max-height: 500px;
    min-height: 360px;
    object-fit: cover;
    width: 100%;
  }

  .meta {
    margin-bottom: $h3;
  }

  .title {
    color: $black;
    font-size: $h35;
  }

  .preview {
    margin-top: 1rem;
  }

  .BtnShare {
    bottom: 2rem;
    position: absolute;
    right: 3rem;
  }
}
</style>

<i18n>
{
  "de": {
    "readMore": "Mehr lesen"
  },
  "en": {
    "readMore": "Read more"
  },
  "it": {
    "readMore": ""
  },
  "lt": {
    "readMore": "Skaityti daugiau"
  },
  "pl": {
    "readMore": "Pokaż więcej"
  }
}
</i18n>
