<template>
  <div id="FaqLandowners">
    <Hero class="solution" :formats="['webp']" src="../../../assets/field.jpg">
      <Row>
        <Column :lg="6">
          <h2>{{ $t('title') }}</h2>
        </Column>
      </Row>
      <HeroScrollDown hash="#sectionFaq" slot="outside" />
    </Hero>
    <SectionAccordion id="sectionFaq">
      <div class="SectionAccordion-accordions">
        <div
          v-for="section in $ta('faqSections')"
          :key="section.title"
          class="faq-section">
          <h3>{{ section.title }}</h3>
          <Qa
            v-for="({ q, a, number }, index) in section.faq"
            :key="index"
            :answer="a"
            :number="number"
            :question="q" />
        </div>
      </div>
    </SectionAccordion>
  </div>
</template>

<script>
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
import Qa from 'views/components/Qa'
import SectionAccordion from 'views/components/SectionAccordion'
import faqSections from './faqSections'

export default {
  mixins: [Meta()],
  components: { Hero, HeroScrollDown, Qa, SectionAccordion },
  i18n: { sharedMessages: faqSections },
}
</script>

<style lang="scss">
#FaqLandowners {
  .faq-section {
    margin-top: 4rem;

    > h3 {
      font-size: $h4;
      font-weight: $semibold;
      margin-bottom: 2rem;
    }

    h4 {
      font-size: $h4;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "meta": {
      "title": "Häufig gestellte Fragen"
    },
    "title": "Häufig gestellte Fragen"
  },
  "en": {
    "meta": {
      "title": "Frequently Asked Questions"
    },
    "title": "Frequently Asked Questions"
  },
  "it": {
    "meta": {
      "title": ""
    },
    "title": ""
  },
  "lt": {
    "meta": {
      "title": "D.U.K"
    },
    "title": "D.U.K"
  },
  "pl": {
    "meta": {
      "title": "Najczęściej zadawane pytania"
    },
    "title": "Najczęściej zadawane pytania"
  }
}
</i18n>
