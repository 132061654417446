<template>
  <div id="PaginationShowMore">
    <div class="pagination">
      <span class="shown-selection">
        <strong>{{ limit }}</strong>/{{ total }}
      </span>

      <Btn
        class="show-more inv"
        :disabled="total === limit"
        @click="paginate">
        {{ $t('more') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'

export default {
  props: {
    limit: { type: Number, required: true },
    total: { type: Number, required: true },
    increment: { type: Number, default: 10 },
    name: { type: String, required: true },
  },
  computed: {
    currentPagination() {
      return this.$store.getters.getPagination(this.name)
    },
    upperLimit() {
      return this.getBoundedLimit()
    },
    ...getters(),
  },
  methods: {
    getBoundedLimit() {
      return Math.min(this.limit + this.increment, this.total)
    },
    paginate() {
      if (this.currentPagination) {
        this.PATCH_PAGINATION({ pagination: this.currentPagination, limit: this.getBoundedLimit() })
      } else {
        this.PUSH_PAGINATION({ name: this.name, limit: this.getBoundedLimit() })
      }

      this.$emit('update:limit', this.currentPagination.limit)
    },
    getPagination() {
      if (this.currentPagination) { this.$emit('update:limit', this.currentPagination.limit) }
    },
    ...actions('PUSH_PAGINATION', 'PATCH_PAGINATION'),
  },
  beforeMount() {
    this.LOAD(this.currentPagination)
    this.getPagination()
  },
}
</script>

<style lang="scss">
#PaginationShowMore {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
  margin-top: 2rem;
  @include md {
    margin-bottom: 4rem;
    margin-top: 4rem;
  }

  .shown-selection {
    color: $black;
    opacity: 0.5;
  }

  .show-more {
    margin-left: 2rem;
    padding: 0.75rem 1.5rem;
  }
}
</style>

<i18n>
{
  "de": {
    "more": "Zeig mehr"
  },
  "en": {
    "more": "Show more"
  },
  "it": {
    "more": ""
  },
  "lt": {
    "more": "Daugiau"
  },
  "pl": {
    "more": "Pokaż więcej"
  }
}
</i18n>
