<template>
  <section class="SectionAccordion">
    <Wrap>
      <slot />
    </Wrap>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss">
.SectionAccordion-accordions {
  background-color: $white;
  padding: 1.5rem 1rem 0;
  @include md {
    padding: 3rem 3rem 1.5rem;
  }
  @include lg {
    padding: 6rem 6rem 3rem;
  }
}
</style>
