<template>
  <Card class="CardPost">
    <Splitter :left="5" :right="7">
      <Pic
        :alt="post.title"
        class="image"
        slot="left"
        :src="getPostImagePreview(post)" />

      <div slot="right" class="body">
        <div class="meta">
          <Tag class="md">
            {{ post.tag }}
          </Tag>

          <span class="date">
            {{ $d(new Date(post.date), 'short') }}
          </span>
        </div>
        <Route class="title" :params="{ slug: post.slug }" to="Post">
          {{ post.title }}
        </Route>

        <BtnShare :to="{ name: 'Post', params: { slug: post.slug } }" />
      </div>
      <div v-if="isNewsPress" slot="right">
        <div class="show-more">
          <Route class="Btn" :params="{ slug: post.slug }" to="Post">
            {{ $t('readMore') }}
          </Route>
        </div>
      </div>
    </Splitter>
  </Card>
</template>

<script>
import BtnShare from './BtnShare'
import Splitter from './Splitter'
import { getters } from '../utils'

export default {
  components: { BtnShare, Splitter },
  props: {
    post: Object,
  },
  computed: {
    isNewsPress() {
      return this.$route.name === 'NewsPress'
    },
    ...getters('getPostImagePreview'),
  },
}
</script>

<style lang="scss">
.CardPost {
  border: 0;

  .show-more {
    margin-top: 2rem;

    a {
      color: $white;
    }
  }

  .Splitter-body {
    height: 100%;
    max-width: unset;
    padding: 2rem;
    width: 100%;
    @include lg {
      padding: 5.75rem 4rem 4rem;
    }
  }

  .image > img {
    display: block;
    max-height: 500px;
    min-height: 360px;
    object-fit: cover;
    width: 100%;
    @include lg {
      min-height: 500px;
    }
  }

  .meta {
    margin-bottom: $h2;

    .date {
      margin-left: 0.5rem;
      @include md {
        margin-left: 2rem;
      }
    }
  }

  .title {
    color: $black;
    font-size: $h35;
  }

  .BtnShare {
    bottom: 2rem;
    position: absolute;
    right: 3rem;
  }
}
</style>

<i18n>
{
  "de": {
    "readMore": "Mehr lesen"
  },
  "en": {
    "readMore": "Read more"
  },
  "it": {
    "readMore": ""
  },
  "lt": {
    "readMore": "Skaityti daugiau"
  },
  "pl": {
    "readMore": "Pokaż więcej"
  }
}
</i18n>
