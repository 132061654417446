<template>
  <div v-if="post" id="Post">
    <Hero :src="getPostImage(post)">
      <h1>
        {{ post.title }}
      </h1>
      <HeroScrollDown hash="#Post-content" slot="outside" />
    </Hero>
    <div id="Post-content" :class="{ 'has-next': postNext }">
      <Wrap>
        <p>
          <strong>{{ $d(new Date(post.date), 'short') }}</strong>
        </p>
        <Markdown :hasStyles="true" :markdown="post.content" />
        <BtnShare />
      </Wrap>
    </div>
    <div v-if="postNext" id="Post-next">
      <Wrap>
        <h3>{{ $t('next') }}</h3>
        <CardPost :post="postNext" />
      </Wrap>
    </div>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import BtnShare from 'views/components/BtnShare'
import CardPost from 'views/components/CardPost'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import Meta from 'views/mixins/Meta'
const Markdown = () => import(/* webpackChunkName: 'showdown' */ 'views/components/Markdown')

export default {
  mixins: [
    Meta(
      ({ post }) => post?.title,
      ({ post }) => post?.content,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: { BtnShare, CardPost, Hero, HeroScrollDown, Markdown },
  data() {
    return {
      showEnglishLocale: [
        'de',
      ],
    }
  },
  computed: {
    localeCurrent() {
      return this.showEnglishLocale.includes(this.locale) ? 'en' : this.locale
    },
    post() {
      return this.query('post', { slug: this.$route.params.slug }, true)
    },
    postNext() {
      return this.query('post', {
        locale: this.localeCurrent,
        $sort: {
          id: -1,
        },
        id: {
          $lt: this.post.id,
        },
      }, true)
    },
    ...getters('getPostImage'),
  },
  methods: actions('FETCH_POST'),
  async beforeMount() {
    await this.LOAD(this.FETCH_POST())
  },
}
</script>

<style lang="scss">
#Post {
  .Hero {
    h1 {
      font-size: $h3;
    }

    img {
      filter: opacity(0.3);
    }
  }
}

#Post-content {
  background: $white;
  padding: 10rem 0;

  p {
    margin-bottom: 2rem;
    // text-indent: 1rem;
  }

  .BtnShare {
    float: right;

    > .BtnRound {
      background: $grey-lighter;
    }
  }

  &.has-next {
    margin-bottom: 10rem;
  }
}

#Post-next {
  margin-bottom: 8rem;

  h3 {
    margin-bottom: 6rem;
    text-align: center;
  }
}
</style>

<i18n>
{
  "de": {
    "next": "Lesen Sie weiter"
  },
  "en": {
    "next": "Read next"
  },
  "it": {
    "next": ""
  },
  "lt": {
    "next": "Kitas įrašas"
  },
  "pl": {
    "next": "Czytaj następny"
  }
}
</i18n>
