<template>
  <Accordion
    ref="el"
    :id="id"
    class="Qa"
    :markdown="answer"
    :title="`${number}. ${question}`" />
</template>

<script>
import Accordion from 'views/components/Accordion'

export default {
  components: { Accordion },
  props: {
    answer: String,
    number: Number,
    question: String,
  },
  computed: {
    id() {
      return `Qa-${this.number}`
    },
  },
  mounted() {
    if (this.$route.hash === `#${this.id}`) this.$refs.el.open()
  },
}
</script>

<style lang="scss">
</style>
